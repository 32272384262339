/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-07-08",
    versionChannel: "nightly",
    buildDate: "2024-07-08T00:05:54.104Z",
    commitHash: "acc84769b6de44ec588825c5d2e725b9b4da09f3",
};
